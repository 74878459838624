import "../../App.css";
import styles from "./Hero.module.css";

export const Hero: React.FC<{ scrollPos: number }> = ({ scrollPos }) => {
  return (
    <>
      <div
        className={`container ${styles.heroContainer}`}
        style={{
          transform: `translateY(${scrollPos * -0.2}px)`,
        }}
      >
        <div className={styles.heroTextContent}>
          <h1 className={styles.heroTextHeadline}>
            Wilderness logistics made{" "}
            <span className={styles.heroHeadlineEmphasis}>simple.</span>
          </h1>
          <h3 className={styles.heroTextContentSubheader}>
            Introducing the ultimate solution for wilderness tripping
            organizations - our software is designed to help you manage all of
            your logistics with ease!
          </h3>
        </div>
      </div>
      <div className={styles.rotationOverlapContainer}>
        <div className={styles.rotationContainer}>
          <img
            src="sample.png"
            id="rotating-img"
            alt="A sample of the bigboard"
            style={{
              transform: `rotateY(${25 + scrollPos * -0.05}deg) scale(${
                1 + scrollPos * 0.0001
              }) translateX(${Math.pow(0.9925, scrollPos - 600)}px)`,
              borderRadius: 8,
            }}
          />
        </div>
      </div>
    </>
  );
};
